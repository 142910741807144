.section {

    margin-top: 90px;
    margin-bottom: -115px;

    .news {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: column;

        @media (max-width: 670px) {
            align-items: center;
        }
    }

    .cols {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 670px) {
            flex-direction: column;
        }

        .col:nth-child(2) {
            .slide:nth-child(even) {
                height: 320px;
            }
            .slide:nth-child(odd) {
                height: 370px;
            }
        }
    }

    .slide {
        height: 320px;
        transition: .3s;
        padding: 40px 24px 40px 13px;
        border-radius: 10px;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: relative;
        width: 340px;
        margin-bottom: 20px;

        &:nth-child(even) {
            height: 370px;
        }
    
        .title {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    
        .text {
            margin: 16px 0;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .date {
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    
        .iconBlock {
            position: absolute;
            bottom: 16px;
            left: 30px;
        }
    }

    .button {
        padding: 12px;
        max-width: 255px;
        width: 100%;
        margin: 40px auto 0;
        display: block;
    }
}