.footer {
    background: #535353;
    color: #fff;
    padding: 50px 10px;

    .contact {
        margin-top: 20px;
        width: 170px;
        height: 40px;
    }

    .cols {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;

        .col {
            flex-grow: 1;
            margin-bottom: 30px;

            >a {
                display: block;
            }

            .titleCol {
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 16px;
            }

            .mailLink {
                text-decoration: underline;
            }

            .footerLink {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 2px;
            }

            .mailLink {
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                margin-bottom: 2px;
                text-decoration: underline;
            }

            .socials {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-left: 50px;

                > * {
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

            }

            .icon {
                width: 100px;
                height: 30px;
                margin-right: 10px;
                margin-top: 10px;
            }

            .iconBank {
                width: 100px;
                height: 30px;
                margin-right: 10px;
                margin-top: 10px;
                object-fit: contain;
            }

            @media (max-width: 670px) {
                .icon {
                    width: 28vw;
                    height: 8.5vw;
                }
            }
        }
    }

    .row {
        display: flex;
    }

    .rowBank {
        display: flex;
        margin-top: 12px;
    }

    .footerBottom {
        display: flex;
        align-items: center;
        margin-top: 24px;
        flex-wrap: wrap;

        .col {
            margin-bottom: 20px;

            @media (max-width: 670px) {
                display: flex;
                flex-direction: column;
                width: 100%;

                p {
                    margin-left: 0px;
                    margin-top: 10px;
                }

                .year {
                    margin-left: 0px!important;
                    margin-top: 10px;
                }

                .policy {
                    margin-top: 10px;
                    margin-left: 0px!important;
                }
            }

            &:first-child {
                display: flex;
                align-items: center;

                .year {
                    opacity: 0.4;
                    display: flex;
                    align-items: center;
                    margin-left: 24px;
                }
            }

            a {
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                text-decoration-line: underline;
            }
        }

        .policy {
            display: block;
            margin-left: 30px;
        }

        .socials {
            width: 100%;
            display: flex;
            align-items: center;

            > a {
                margin-right: 16px;
            }
        }
    }
}