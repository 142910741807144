.wrap {

    position: relative;

    svg {
        position: absolute;
        right: 20px;
        bottom: 13px;
        z-index: 10;
    }

    > div {
        width: 100%;
    }

    .button {
        background: transparent;
        border: none;
        color: inherit;
        font-family: Montserrat;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 20px;
        background: #B0D20B;
        padding: 0px 10px;
        margin: 0px 10px;
        color: white;
    }
}