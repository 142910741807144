.section {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 52px 0;

    @media screen and (max-width: 670px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 52px 20px;
    }

    .content {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1027px) {
            margin-left: 0;
        }

        .active {
            display: flex;
            padding: 6px 25px;
            justify-content: center;
            align-items: center;
            border-radius: 40px;
            font-size: 22px;
            border: 2px solid #A2B83C;
            color: #A2B83C;
            margin: 10px;
        }

        .normal {
            color: #202020;
            text-align: center;
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 10px;
        }
    }
}