.section {
    margin-top: 90px;

    .items {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @media (max-width: 670px) {
            flex-direction: column;
            align-items: center;
        }

        .block {
            text-align: center;
            width: 100%;
            max-width: 340px;

            @media (max-width: 670px) {
                margin-bottom: 20px;
            }

            .title {
                color: #202020;
                text-align: center;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin: 20px 0;
            }

            .text {
                color: #202020;
                text-align: center;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}