.more {
    width: 157px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px auto 0;

    @media (max-width: 670px) {
        width: 95%;
    }
}