.section {
    background: url(../../assets/img/bannerBg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 52px 10px;
    overflow: hidden;

    @media (max-width: 670px) {
        margin-top: 0px;
    }

    .qrContainer {
        background: white;
        padding: 10px;
        border-radius: 10px;
        margin-left: 100px;

        @media (max-width: 670px) {
            display: none;
        }
    }

    .mainImage {
        position: absolute;
        right: -100px;
        bottom: -300%;
        transform: rotate(25deg);
        width: 800px;
        object-fit: cover;
        z-index: 0;

        @media (max-width: 670px) {
            width: 500px!important;
            right: -150px;
            bottom: -280px;
        }
    }
    .row {
        display: flex;
        z-index: 1;

        .icon {
            width: 100px;
            height: 30px;
            margin-right: 10px;
            margin-top: 10px;
        }

        @media (max-width: 670px) {
            .icon {
                width: 28vw;
                height: 8.5vw;
            }
        }
    }

    .title {
        z-index: 1;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 15px 0px;

        @media (max-width: 670px) {
            width: 50vw;
            text-align: center;
        }
    }

    .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px 0;
    }

    .content {
        max-width: 580px;
        margin-left: auto;
        padding: 0px 20px;
        @media (max-width: 670px) {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .button {
        padding: 8px 20px;
        position: relative;
        z-index: 10;
    }
}