.dropdown {

  position: relative;
    
  .dropdown__arrow {
    position: absolute;    
    left: 50%;
    transform: translateX(-50%);
    top: -20px;
    width: 0; 
    height: 0; 
    border-radius: 3px;
  }
  
  .dropdown__list {
    // opacity: 0;
    transition: max-height .6s ease-out;
    // transition: opacity .15s ease-out;
    max-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: none;

    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
  }

  .active {
    max-height: 1000px;
    opacity: 1;

    position: absolute;
    width: 100%;
    padding: 10px;
    display: block;
    margin-top: 5px;
  }
  
  .item {
    cursor: pointer;
    list-style: none;    
    padding: 5px;

    .menuItem {
      color: black
    }
  }
}

.user {
  display: flex;

  .avatar {
      width: 30px;
      height: 30px;
      border-radius: 100px;
      background: #A8A7EE;
      display: flex;
      align-items: center;
      justify-content: center;

      .nickName {
          color: #FFF;
          text-align: center;
          font-family: Montserrat;
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
      }
  }
  
  .name {
      color: #FFF;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
  }
}