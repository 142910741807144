.content {
    background: url('../../assets/img/download_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-bottom: 80px;

    .title {
        color: #FFF;
        font-family: Montserrat;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 58px;
        margin: 185px 0 0;

        span {
            color: #9ADA00;
        }
    }

    .column {
        margin-top: 40px;
        margin-bottom: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .row {
        align-content: center;
        z-index: 1;

        .icon {
            width: 200px;
            height: 62px;
            margin-right: 54px;
            margin-top: 10px;
        }

        @media (max-width: 670px) {
            .icon {
                width: 28vw;
                height: 8.5vw;
            }
        }
    }

    .column_mobile {
        margin-top: 40px;
        align-items: center;
        justify-content: center;
    }

    .row_mobile {
        align-content: center;
        z-index: 1;

        .icon {
            width: 270px;
            height: 62px;
            margin-top: 24px;
        }

        @media (max-width: 670px) {
            .icon {
                width: 28vw;
                height: 8.5vw;
            }
        }
    }
}