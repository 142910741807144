.header {
    background: url('../../assets/img/header-bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 0 0 155px;
    position: relative;
    height: 460px;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }



   

    .headerContent {
        margin: 85px 0 16px;

        .title {
            text-align: center;
            font-size: 44px;
            font-style: normal;
            font-weight: 600;
            color: #9ADA00;

            @media (max-width: 670px) {
                font-size: 25px;
            }

            span {
                color: #fff;
            }
        }
    }

    .pick {
        height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
    }

    .search {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        margin-left: 4px;
    }

    .toolbar {
        display: none;
        width: 100%;
        justify-content: center;
        margin: 10px 0px;

        @media (max-width: 670px) {
            display: flex;            
        }
    }
}