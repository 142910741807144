.section {
    background: #F3F6F8;
    margin-top: 65px;
    padding: 32px 0;

    .mainImage {
        position: absolute;
        left: 0;
        bottom: -32px;
        @media screen and (max-width: 670px) {
            position: relative;
        }
    }

    .title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px 0;
    }

    .content {
        max-width: 530px;
        margin-left: auto;
        @media screen and (max-width: 670px) {
            margin-left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
    }

    .button {
        padding: 8px 20px;
        position: relative;
        z-index: 10;
    }
} 