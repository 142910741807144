.content {
    background: url('../../assets/img/iStock-1421336719\ 1\(1\).jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-bottom: 80px;

    .title {
        color: #FFF;
        font-family: Montserrat;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 58px;
        margin: 185px 0 0;

        span {
            color: #9ADA00;
        }
    }
}