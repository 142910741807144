.section {
    .tabs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 670px) {
            flex-direction: row!important;
            align-items: center!important;
            justify-content: center!important;
        }

        .tab {
            max-width: 234px;
            width: 100%;
            display: flex;
            align-items: center;
            border-radius: 10px;
            margin-bottom: 30px;
            justify-content: center;
            padding: 12px;
            p {
                margin-left: 12px;
            }
        }
    }
}