.content {
    max-width: 930px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 670px) {
        flex-direction: column;
        padding: 20px;
    }

    .title {
        color: #202020;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
    }

    .text {
        color: #3A3A3A;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
    }

    .textContent {
        max-width: 590px;
    }
}

.section {
    padding: 90px 0;
}