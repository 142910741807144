.slider {
    height: 372px;
    overflow-y: visible !important;

    @media (max-width: 670px) {
        height: max-content;
    }
}

.slide {
    height: 340px;
    transition: .3s;
    padding: 40px 24px 40px 13px;
    border-radius: 10px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;

    .title {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text {
        margin: 16px 0;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .date {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .iconBlock {
        position: absolute;
        bottom: 16px;
        left: 30px;
    }
}