.content {
    border-radius: 40px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;

    @media (max-width: 670px) {
        width: 80%;
    }

    .toolbar {
        display: flex;

        @media (max-width: 670px) {
            display: none;            
        }
    }

    .input {
        display: flex;
        align-items: center;

        svg {
            margin-right: 4px;
        }

        input {
            padding: 8px 0;
            width: 100%;
            color: #3A3A3A;


            &::placeholder {
                opacity: 1;
            }
        }

    }

    .line {
        margin: 0 22px 2px 37px;
        display: block;
        min-width: 1px;
        height: 33px;
        border-radius: 20px;
        background: #E4EAEE;

        @media (max-width: 670px) {
            margin: 0 5px;
        }
    }

    button {
        border-radius: 20px;
        background: #B0D20B;

        &:hover {
            background: #CCED29;
        }
    }

    .pickToDefault {
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
    }

    .pick {
        height: 32px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
    }

    .search {
        padding: 8px 20px;
        display: flex;
        align-items: center;
        margin-left: 4px;
    }
}