.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
        position: absolute;
        background: #0000005d;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .bigline {
        width: 100%;
        height: 2px;
        margin: 40px 0px;
        background-color: #D3E0EA;
    }

    .content {
        width: 484px;
        max-width: 484px;
        padding: 80px 40px;
        padding-bottom: 30px;
        position: relative;
        z-index: 10;
        background: #fff;
        border-radius: 4px;
        min-height: 550px;

        @media (max-width: 670px) {
            width: 95%!important;
            margin: auto!important;
        }

        .sendSmS {
            height: 40px;
            margin-top: 20px;
            width: 100%;
        }

        .closeBtn {
            position: absolute;
            top: 30px;
            right: 30px;
            background: transparent;
            width: 40px;
            height: 40px;
        }


        .title {
            color: #3A3A3A;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 30px;
        }
        .smallTitle {
            cursor: pointer;
            color: #87BE02;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 30px;
        }

        .inputWrap {
            margin-bottom: 20px;
            position: relative;

            .inputTitle {
                color: #3A3A3A;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                border-radius: 20px;
                border: 1px solid #D3E0EA;
                padding: 12px 22px;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                &.phoneInput {
                    // padding-left: 85px;
                }
            }

            .country {
                background: transparent;
                position: absolute;
                bottom: 12px;
                display: flex;
                align-items: center;
                left: 22px;
                padding-right: 10px;
                border-right: 1px solid #D3E0EA;
                border-radius: 0px;

                svg {
                    margin-right: 6px;
                }
            }

            .showPassword {
                position: absolute;
                background: transparent;
                color: #3A3A3A;
                text-align: right;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                bottom: 10px;
                right: 24px;
            }
        }

        .ruleContainer {
            margin-left: 15px;
            margin-bottom: 10px;

            .ulHeader {
                color: var(--grayscale-600, #5F697C);
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: bold;
                letter-spacing: -0.14px;
            }

            ul {
                margin-left: 25px;
            }

            .text {
                color: var(--grayscale-600, #5F697C);
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.14px;
            }
        }

        .login {
            width: 100%;
            margin-top: 10px;
            padding: 12px 0;
            text-align: center;
            margin-bottom: 12px;
            background: none!important;
            border: 2px solid #87BE02;
            color: #87BE02;
            font-weight: bold;

            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .next {
            width: 100%;
            margin-top: 10px;
            padding: 12px 0;
            text-align: center;
            margin-bottom: 12px;
            background-color: #87BE02;
            border: 2px solid #87BE02;
            color: #3A3A3A;
            font-weight: bold;

            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .forgotPassword {
            color: #638A05;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            width: 100%;
            text-align: center;
            display: block;
        }

        .terms {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-top: 30px;
            margin-bottom: 6px;

            a {
                text-decoration: underline;
                font-weight: 500;
                color: #87BE02;
            }
        }

        .steps {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin-top: 24px;
            margin-bottom: 30px;

            .line {
                width: 50px;
                height: 3px;
                background-color: #D3E0EA;
            }

            .step {
                color: #3A3A3A;
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                cursor: pointer;
                transition: .3s;
                border-radius: 100px;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                &.active {
                    background-color: #B0D20B;
                }
            }
        }

        .step2Terms {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-bottom: 20px;
        }

        .timerText {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            margin-top: 20px;
            text-align: center;
        }
    }

    .genders {
        display: flex;
        align-items: center;
        margin-top: 13px;

        .gender {
            display: flex;
            align-items: center;
            margin-right: 24px;
            cursor: pointer;

            p {
                margin-left: 12px;
                color: #3A3A3A;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
            }

            span {
                width: 20px;
                height: 20px;
                border: 1px solid #D3E0EA;
                display: flex;
                background: #F9FBFF;
                border-radius: 100%;
                justify-content: center;
                align-items: center;
                position: relative;

                .activeCircle {
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    border: 1px solid #87BE02;
                    background: #87BE02;
                }

                &.active {
                    background: white;
                    border-color: #87BE02;
                }
            }
        }
    }

    .inputWrap1 {
        margin-bottom: 20px;
        position: relative;

        .inputTitle {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #D3E0EA;
            padding: 20px 22px;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        * {
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}