.section {
    background: url('../../assets/img/Group\ 473827.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 40px 45px;

    @media (max-width: 670px) {
        padding: 10px!important;
    }

    .title {
        color: #202020;
        font-family: Montserrat;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 670px) {
            flex-direction: column;
        }

        .item {
            .value {
                color: #BAD774;
                text-align: center;
                font-family: Montserrat;
                font-size: 68px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 23px;
            }

            &:nth-child(2) {
                .value {
                    color: #B0D20A;
                }
            }

            &:nth-child(3) {
                .value {
                    color: #638A05;
                }
            }

            .text {
                color: #202020;
                text-align: center;
                font-family: Montserrat;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}