.section {
    .steps {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 90px;

        @media screen and (max-width: 670px) {
            display: block;
        }

        .step {
            text-align: center;
            flex-grow: 1;
            height: 470px;
            margin: 10px;
            border-radius: 8px;
            background: #F3F6F8;
            position: relative;
            padding-top: 26px;
            margin-bottom: 20px;

            .number {
                font-size: 98px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-bottom: 10px;
            }

            &:nth-child(1) {
                .number {
                    color: #BAD774;
                }
            }

            &:nth-child(2) {
                .number {
                    color: #B0D20B;
                }
            }

            &:nth-child(3) {
                .number {
                    color: #638A05;
                }
            }

            .text {
                width: 325px;
                text-align: center;
                margin: 0 auto;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            img {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-radius: 10px;
            }
        }
    }
}

.welcome {
    color: #3A3A3A;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 90px;

    @media (max-width: 670px) {
        padding: 20px;
    }
}