.title {
    color: #3A3A3A;
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;

    @media (max-width: 670px) {
        text-align: center;
    }
}