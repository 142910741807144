.section {
    background: #F3F6F8;
    margin-top: 195px;
    padding: 62px 10px;
    @media (max-width: 670px) {
        padding: 0px!important;
        margin-top: 0px;
    }

    .mainImage {
        position: absolute;
        left: 0;
        bottom: -62px;
        @media (max-width: 670px) {
            // display: none;
            position: relative;
            width: 100vw;
            bottom: 0px;
        }
    }

    .title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 20px 0;
    }

    .content {
        max-width: 580px;
        margin-left: auto;
        padding: 0px 20px;
        @media (max-width: 670px) {
            padding-top: 50px;
            background: #F3F6F8;
            margin-left: 0;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
    }

    .button {
        padding: 8px 20px;
        position: relative;
        z-index: 10;
    }
} 