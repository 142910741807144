.section {
    padding: 90px 0 0;

    .items {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 29px;
        grid-row-gap: 40px;

        justify-content: center;

        @media screen and (max-width: 670px) {
            grid-template-columns: repeat(5, 1fr);
        }

        @media screen and (max-width: 880px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media screen and (max-width: 690px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media screen and (max-width: 510px) {
            grid-template-columns: repeat(2, 1fr);
        }


        .serviceItem {
            text-align: center;
            padding: 7px 0;
            display: flex;
            flex-direction: column;
            align-items: center;

            div {
                border: 6px solid #B0D20A;
                border-radius: 100px;
                width: fit-content;
                display: grid;
                padding: 20px;
            }

            .itemText {
                color: #000;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-top: 20px;
            }
        }
    }
}