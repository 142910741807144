.section {
    background: #E0F1B4;
    padding: 24px 0;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 275px;
        margin: 0 auto;
        width: 100%;
    }
}