.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
        position: absolute;
        background: #0000005d;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .content {
        width: 100%;
        padding: 50px 40px;
        position: relative;
        z-index: 10;
        border-radius: 4px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        @media (max-width: 670px) {
            flex-direction: column;
        }

        .body {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 120px 100px 0px 100px;

            .title {
                color: #3A3A3A;
                font-family: Montserrat;
                font-size: 30px;
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                margin-bottom: 30px;
                text-align: center;
            }
            
            .row {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 150px;
                    height: 45px;
                    margin-right: 10px;
                    margin-top: 10px;
                }
    
                @media (max-width: 670px) {
                    .icon {
                        width: 28vw;
                        height: 8.5vw;
                    }
                }
            }
        }

        .closeBtn {
            position: absolute;
            top: 30px;
            right: 30px;
            background: transparent;
            width: 40px;
            height: 40px;
        }

        .card {
            border-radius: 15px;
            overflow: hidden;
            position: relative;
            height: 60vh;

            @media (max-width: 670px) {
                .card_bg {
                    width: 95vw!important;
                }

                .body {
                    padding: 10px;
                    padding-top: 75px;
                }

                .phone {
                    left: -33vw !important;
                    width: 160vw!important;
                }
            }

            .qrContainer {
                background: white;
                padding: 20px;
                border-radius: 10px;
            }

            .card_bg {
                width: 500px;
                object-fit: cover;
            }   

            .phone {
                position: absolute;
                left: -45%;
                top: 50%;
            }
        }
    }
}