.section {
    margin-bottom: 85px;

    .inputWrap {
        margin-bottom: 20px;
        position: relative;
        margin-left: 20px;

        @media (max-width: 670px) {
            margin-left: 0px;
            width: 100%!important;
        }

        input {
            background: #F9FBFF!important;
        }

        .inputTitle {
            color: #3A3A3A;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 5px;
        }

        input,
        textarea {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #D3E0EA;
            padding: 12px 22px;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            resize: none;

            &.phoneInput {
                // padding-left: 85px;
            }
        }

        input {
           max-width: 335px;

           @media (max-width: 670px) {
                max-width: 1000px;
           }
        }

        textarea {
            height: 130px;
        }

        .country {
            background: transparent;
            position: absolute;
            bottom: 12px;
            display: flex;
            align-items: center;
            left: 22px;
            padding-right: 10px;
            border-right: 1px solid #D3E0EA;
            border-radius: 0px;

            svg {
                margin-right: 6px;
            }
        }

        .showPassword {
            position: absolute;
            background: transparent;
            color: #3A3A3A;
            text-align: right;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            bottom: 13px;
            right: 24px;
        }
    }

    .content {
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 670px) {
            flex-direction: column;
        }

        .info {
            background: url('../../assets/img/getBg.jpg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            height: 100%;
            padding: 40px;
        }

        .title {
            color: #202020;
            font-family: Montserrat;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            margin-bottom: 30px;
            line-height: normal;
        }

        .smallTitle {
            color: #202020;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 10px;
            line-height: normal;
        }


        .infoItem {
            display: flex;
            align-items: flex-start;
            margin-bottom: 16px;

            &:nth-child(1) {
                align-items: flex-start;
            }

            svg {
                margin-right: 10px;
            }

            p {
                max-width: 250px;
                color: #3E3D3F;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .form {
            max-width: 695px;
            width: 100%;
            padding: 20px;
        }
    }

    .inputs {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 670px) {
            flex-direction: column;
            align-items: flex-start!important;
        }
    }

    .submit {
        height: 40px;
        max-width: 400px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            position: absolute;
            right: 10px;
        }
    }

    .inputWrap1 {
        margin-bottom: 20px;
        position: relative;
        margin-left: 20px;

        .inputTitle {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #D3E0EA;
            padding: 20px 22px;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        * {
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}