@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth !important;
}

body,
html {
    overflow-x: hidden;
    scroll-behavior: smooth !important;
}

body {
    min-height: 100vh;
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: #fff;
}

a {
    text-decoration: none;
    color: inherit
}

button {
    background: transparent;
    border: none;
    color: inherit;
    font-family: Montserrat;
    cursor: pointer;
    transition: .3s;
    border-radius: 20px;
    background: #B0D20B;

    &:hover {
        background: #CCED29;
    }
}

input {
    border: none;
    font-family: Montserrat;

    &:focus {
        outline: none;
    }
}

.container {
    max-width: 1100px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.swiper-slide-next {
    height: 370px !important;
}

.swiper-button-prev, .swiper-button-next {
    position: absolute !important;
    top: -45px !important;
    right: 0 !important;
    left: auto !important;
    color: transparent !important;
    height: 32px !important;
    width: 32px !important;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.swiper-button-prev {
    right: 45px !important;
    background: url(./assets/img/prev.svg);
}

.swiper-button-next {
    background: url(./assets/img/next.svg);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
::-webkit-scrollbar-thumb {
    background-color: #d4aa70;
    border-radius: 100px;
}