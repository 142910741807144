.headerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 18px;
    padding-bottom: 23px;
    position: relative;
    z-index: 10;
    margin-bottom: 70px;
    padding: 10px 10px;

    .topContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .drawer {
        display: none;
        background: none;

        @media (max-width: 670px) {
            display: block;
        }
    }

    .headerNav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        @media (max-width: 670px) {
            display: none;
        }

        >* {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0px 20px;
        }

        .loginBtn {
            padding: 3px 23px;
            border-radius: 20px;
            border: 1px solid #FFF;
            background: transparent !important;
        }

        .joinBtn {
            background: transparent;
            color: #FFF;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .languagePick {
            background: transparent;
        }
    }
}

.drawerContent {
    background-color: #BBCE5E;

    .back {
        margin: 20px 0px;
    }

    .container {
        width: 100%;
        height: 100%;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    button {
        background: none!important;

        color: #3A3A3A;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .navItem {
        color: #3A3A3A;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .line {
        width: 100%;
        height: 10px;
        border-bottom: 2px solid #3A3A3A;
        margin: 10px 0px;
    }

    .line1 {
        width: 100%;
        height: 10px;
        margin: 10px 0px;
    }

    .join {
        display: flex;
        justify-content: space-between;
    }

    .signin {
        width: 100%;
        border: 2px solid #3A3A3A;
        border-radius: 30px;
        padding: 10px;
        margin-bottom: 10px;
    }
}