.services {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;

    .item {
        max-width: 152px;
        width: 100%;
        margin-bottom: 45px;

        .iconWrap {
            width: 138px;
            height: 138px;
            border-radius: 298px;
            border: 6px solid #B0D20A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 27px;
            margin: 0 auto;
        }

        .itemTitle {
            color: #202020;
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-align: center;
            margin: 20px 0 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #D3E0EA;
            margin-bottom: 20px;
        }

        .link {
            color: #202020;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 12px;

            span {
                color: #899AA4;
            }
        }

        .more {
            color: #638A05;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            cursor: pointer;
        }
    }
}