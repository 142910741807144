.section {
    margin-top: 90px;

    @media (max-width: 670px){
        margin-top: 0px;
    }
}


.bigCategories {
    padding: 0px 20px;
    width: 100%;

    .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    @media (max-width: 670px) {
        overflow-x: scroll;
        justify-content: start;

        .content {
            width: max-content;
        }
    }

    .item {
        margin-bottom: 30px;
        padding: 10px;
        @media (max-width: 670px){
            margin-right: 30px;
        }
        .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 20px;
        }
        .img {
            width: 100%;
        }
    }
}

.smallCategories {
    padding: 0px 20px;
    width: 100%;

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 40px;
    }

    @media (max-width: 670px) {
        overflow-x: scroll;
        justify-content: start;

        .content {
            width: max-content;
        }
    }

    .item {
        padding: 10px;
        margin-bottom: 20px;
        .text {
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 20px;
        }
        .img {
            width: 100%;
        }
    }
}