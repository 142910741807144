.code {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: text;
    width: 100%;
    margin: 0 auto;

    input {
        position: absolute;
        opacity: 0;
        top: -1000vw;
    }

    .dots {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-around;

        .line {
            border-radius: 6px;
            background: #3a3a3a;
            width: 2px;
            display: block;
            height: 30px;
            animation-name: blinker;
            animation-iteration-count: infinite;
            animation-duration: 1s;
            -webkit-animation-name: blinker;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-duration: 1s;
        }

        .block {
            border-radius: 20px;
            background: #E5EEFF;
            border: 1px solid #E5EEFF;
            padding: 15px 30px;
        }
    
        .focus {
            border-radius: 20px;
            background: #E5EEFF;
            border: 2px solid #B0D20B;
            padding: 15px 30px;
        }

        p {
            font-size: 24px;
        }
    }
}

@keyframes blinker {
    0% {
        opacity: 1.0;
    }

    50% {
        opacity: 1.0;
    }

    100% {
        opacity: 0.0;
    }
}