.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
        position: absolute;
        background: #0000005d;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .content {
        width: 484px;
        max-width: 484px;
        padding: 80px 40px;
        padding-bottom: 30px;
        position: relative;
        z-index: 10;
        background: #fff;
        border-radius: 4px;
        min-height: 550px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (max-width: 670px) {
            width: 95%!important;
            margin: auto!important;
        }

        .closeBtn {
            position: absolute;
            top: 30px;
            right: 30px;
            background: transparent;
            width: 40px;
            height: 40px;
        }


        .title {
            color: #3A3A3A;
            font-family: Montserrat;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 30px;
        }
        .smallTitle {
            cursor: pointer;
            color: #87BE02;
            font-family: Montserrat;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin-bottom: 30px;
        }

        .inputWrap {
            margin-bottom: 20px;
            position: relative;

            .inputTitle {
                color: #899AA4;
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 5px;
            }

            input {
                width: 100%;
                border-radius: 20px;
                border: 1px solid #D3E0EA;
                padding: 12px 22px;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .showPassword {
                position: absolute;
                background: transparent;
                color: #3A3A3A;
                text-align: right;
                font-family: Montserrat;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                bottom: 10px;
                right: 24px;
            }
        }

        .ruleContainer {
            margin-left: 15px;
            margin-bottom: 10px;

            .ulHeader {
                color: var(--grayscale-600, #5F697C);
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: bold;
                letter-spacing: -0.14px;
            }

            ul {
                margin-left: 25px;
            }

            .text {
                color: var(--grayscale-600, #5F697C);
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                letter-spacing: -0.14px;
            }
        }

        .login {
            color: #3A3A3A;
            width: 100%;
            margin-top: 10px;
            padding: 12px 0;
            text-align: center;
            margin-bottom: 12px;
            font-weight: bold;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .forgotPassword {
            cursor: pointer;
            color: #87BE02;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-decoration-line: underline;
            width: 100%;
            text-align: center;
            display: block;
            margin-bottom: 10px;
        }

        .rememberMe {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            width: 100%;
            text-align: center;
            display: block;
            margin-bottom: 15px;
        }
    }

    .inputWrap1 {
        margin-bottom: 20px;
        position: relative;

        .inputTitle {
            color: #899AA4;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-bottom: 5px;
        }

        input {
            width: 100%;
            border-radius: 20px;
            border: 1px solid #D3E0EA;
            padding: 20px 22px;
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        * {
            font-family: Montserrat;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}