.modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    .bg {
        position: absolute;
        background: #0000005d;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
    }

    .content {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        background: #FED599;
        width: 600px;
        padding: 15px 0px 5px 0px;

        @media (max-width: 670px) {
            flex-direction: column;
            height: 450px;
            width: 95vw!important;
            padding: 30px 0px 10px 0px!important;
        }
        
        .OK {
            z-index: 1;
            padding: 10px 50px;
            background: #FFA622;
        }

        .body {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 120px 100px 0px 100px;

            .title {
                color: #3A3A3A;
                font-family: Montserrat;
                font-size: 30px;
                font-style: normal;
                font-weight: bold;
                line-height: normal;
                margin-bottom: 30px;
                text-align: center;
            }
            
            .row {
                display: flex;
                align-items: center;
                justify-content: center;

                .icon {
                    width: 150px;
                    height: 45px;
                    margin-right: 10px;
                    margin-top: 10px;
                }
    
                @media (max-width: 670px) {
                    .icon {
                        width: 28vw;
                        height: 8.5vw;
                    }
                }
            }
        }

        .closeBtn {
            position: absolute;
            top: 30px;
            right: 30px;
            background: transparent;
            width: 40px;
            height: 40px;
        }

        .pattern {
            position: absolute;
            transform: rotate(140deg) scale(0.7);
            left: 0;
            top: -285px;
        }

        .card {
            border-radius: 15px;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: center;
            padding-left: 20px;

            @media (max-width: 670px) {
                flex-direction: column;
                align-items: center;
                padding-left: 0px!important;

                .nnn {
                    right: 0%;
                }

                .text2 {
                    text-align: center!important;
                    padding-left: 0px!important;
                }

                .text3 {
                    text-align: center!important;
                    padding-left: 0px!important;
                }

                .text4 {
                    text-align: center!important;
                    padding-left: 0px!important;
                }
            }

            .ring {
                width: 40%;
            }

            .textContainer {
                display: flex;
                transform: translate(-60px, 0px);
                position: relative;

                @media (max-width: 670px) {
                    height: 250px;
                    transform: none!important;
                }
            }

            .textContainerBody {
                display: flex;
                margin-top: 15px;
                flex-direction: column;
            }

            .hit {
                color: #3288FD;
                font-size: 50px;
                font-style: normal;
                font-weight: bold;
                margin-bottom: 30px;
                text-align: center;
            }

            .text1 {
                color: #000;
                font-size: 25px;
                font-style: normal;
                font-weight: bold;
                text-align: right;
            }

            .text2 {
                color: #000;
                text-align: right;
                font-size: 17px;
                font-style: normal;
                font-weight: bold;
                padding-left: 10%;
            }

            .text3 {
                color: #000;
                text-align: right;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                margin-top: 20px;
            }

            .text4 {
                color: #000;
                text-align: right;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                padding-left: 5%;
            }

            @media (max-width: 670px) {
                .card_bg {
                    width: 95vw!important;
                }

                .body {
                    padding: 10px;
                    padding-top: 75px;
                }

                .phone {
                    left: -33vw !important;
                    width: 160vw!important;
                }
            }

            .qrContainer {
                background: white;
                padding: 20px;
                border-radius: 10px;
            }

            .card_bg {
                width: 500px;
                object-fit: cover;
            }   

            .phone {
                position: absolute;
                left: -45%;
                top: 50%;
            }

        }
    }
}