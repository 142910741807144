.section {
    background: url(../../assets/img/bannerBg.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 195px;
    padding: 52px 0;

    @media screen and (max-width: 670px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 52px 20px;
    }

    .mainImage {
        position: absolute;
        left: 0;
        bottom: -18px;

        @media screen and (max-width: 670px) {
            position: relative;
        }
    }

    .title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 20px;
    }

    .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10px;
    }

    .content {
        max-width: 580px;
        margin-left: auto;
        @media screen and (max-width: 1027px) {
            margin-left: 0;
        }
    }

    .button {
        padding: 8px 20px;
        position: relative;
        z-index: 10;
    }
}