.section {
    margin-top: 90px;
    padding: 10px;

    .welcome {
        color: #3A3A3A;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 90px;
    }

    .cards {
        display: flex;
        align-items: center;
        justify-content: space-around;

        @media (max-width: 670px) {
            flex-direction: column;

            .card {
                width: 100%!important;
            }
        }

        .card {
            border-radius: 30px;
            height: 300px;
            padding: 20px;
            width: 48%;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;

            .borderContent {
                border: 1px solid white;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                padding: 10px;

                .price {
                    color: #282E1A;
                    font-family: Montserrat;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .description {
                    color: #000;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 17px; /* 121.429% */
                    margin-top: 20px;
                }

                .pay {
                    color: #000;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 16px; /* 114.286% */
                    text-decoration-line: underline;
                    cursor: pointer;
                    z-index: 2;
                }

                .next {
                    cursor: pointer;
                    z-index: 2;
                }

                .bottom {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }
            }

            .badge {
                display: inline-flex;
                transform: rotate(36deg);
                padding: 10px 96px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                position: absolute;
                top: 37px;
                right: -58px;

                .text {
                    color: #000;
                    font-family: Montserrat;
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px; /* 117.647% */
                }
            }

            .icon {
                position: absolute;
                left: 0px;
                top: 0px;
            }
        }

        .monthly {
            background: #E0F1B4;

            .icon {
                transform: rotate(147.85deg) translate(-20%, 40%);
            }

            .badge {
                background: #B0D20A;
            }
        }

        .annual {
            background: #BAD774;

            .icon {
                transform: rotate(-22.3deg) translate(32%, -12%);
            }

            .badge {
                background: #FFF;
            }
        }
    }
}
