.dropdown {
  position: absolute;
  right: -100px;
  top: -0px;
  max-width: 300px;

  p {
    color: var(--grayscale-800, #25282D);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #25282D;
    margin-left: 10px;
  }
    
    &__arrow {
      position: absolute;    
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
      width: 0; 
      height: 0; 
      border-radius: 3px;
    }
    
    &__list {
      // opacity: 0;
      transition: max-height .6s ease-out;
      // transition: opacity .15s ease-out;
      max-height: 0;
      overflow: hidden;
      margin: 0;
      padding: 0;

      box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
      
      &--active {
        max-height: 1000px;
        opacity: 1;
      }
    }
    
    &__list-item {
      cursor: pointer;
      list-style: none;    
      padding: 5px;
    }
}